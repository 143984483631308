<template>
  <div class="page-container">
      敬请期待
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.page-container {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>>