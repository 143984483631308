<template>
  <div id="app">
    <PlaceHolder />
  </div>
</template>

<script>
import PlaceHolder from './components/PlaceHolder.vue'

export default {
  name: 'App',
  components: {
    PlaceHolder
  }
}
</script>

<style>

</style>
